<template>
  <div id="version">

    <div class="content">
      <div class="Breadcrumb">
        <b-breadcrumb>
          <b-breadcrumb-item href="#/">
            <b-icon icon="house"
                    scale="1.25"
                    shift-v="1.25"
                    aria-hidden="true"></b-icon>
            首页
          </b-breadcrumb-item>
          <b-breadcrumb-item href="#/production">产品中心</b-breadcrumb-item>
          <b-breadcrumb-item active>产品版本</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <div class="item_fa">
        <div class="item"
             v-for="(item,i) in list"
             :key="i">
          <div class="title">测量大师{{item.version_name}} <em>({{item.type==1?"Android":"IOS"}})</em> <span v-if="i==0">NEW</span></div>
          <div class="time">
            <b-icon-clock></b-icon-clock><span>{{item.created_at}}</span>
          </div>
          <div class="info">
            <div v-for="(v,index) in item.info"
                 :key="index">{{v}}</div>
          </div>
          <div class="ewm"
               v-if="i==0">
            <b-img :src="require('../../assets/img/production/QRCode.png')"></b-img>
            <div class="des">扫码下载</div>
          </div>
        </div>
        <div class="item more"
             v-show="!is_all"
             @click="more">查看更多</div>
        <div class="item more"
             v-show="is_all">已加载全部</div>
      </div>
    </div>
    <myhead :background="'dark'"></myhead>
    <foot></foot>
    <right></right>
  </div>
</template>

<script>
import myhead from '@/components/head.vue'
import foot from '@/components/foot.vue'
import right from '@/components/right.vue'
export default {
  name: "version",
  data () {
    return {
      list: [

      ],
      is_all: false,
      dataTable: [
      ],
      dataPagination: {
        currentPage: 1, //当前页码
        perPage: 15, //每页显示
        lastPage: 1, //总页码
        total: 1, //总条
      },
    }
  },
  components: {
    foot,
    right,
    myhead
  },
  mounted () {
    this.appinfo()
  },
  methods: {
    more () {
      this.dataPagination.currentPage++;
      this.appinfo()
    },
    appinfo () {
      let params = {
        with_paginate: 1,
        page: this.dataPagination.currentPage,
      }
      this.$axios.get(`${this.$Tools.Conts.domain}versions`, { params }).then(res => {
        if (res.data.code == 0) {
          res.data.data.data.map((val) => {
            val.info = val.content.split("\n")
          })
          if (this.dataPagination.currentPage == 1) {
            this.list = res.data.data.data
          } else {
            res.data.data.data.map((val) => {
              this.list = this.list.concat(val)
            })
          }
          if (res.data.data.data.length == 0) {
            this.$toast.warn({
              message: "暂无更多数据",
              timeOut: 2000
            })
            this.is_all = true
          }

          Object.assign(this.dataPagination, {
            lastPage: res.data.data.last_page, //总页码
            perPage: res.data.data.per_page, //每页显示
            total: res.data.data.total, //总条数
          });
        } else {
          this.$toast.warn({
            message: res.data.message,
            timeOut: 2000
          })
        }

      })
    }
  }
}
</script>

<style lang="less" scoped>
#version {
  background: rgb(247, 247, 247);
}
.content {
  padding: 5rem 0 2rem;
  .Breadcrumb {
    width: 90%;
    margin: auto;
    .breadcrumb {
      background: transparent;
      padding: 0rem 0;
      font-size: 0.8rem;
      a {
        color: #2b85e4;
      }
    }
  }
  .item {
    background: white;
    border-radius: 6px;
    padding: 1.5rem;
    width: 90%;
    margin: 0 auto 0.8rem;
    position: relative;
    &:first-child {
      .info {
        width: 70%;
      }
    }
    .title {
      font-size: 1rem;
      padding-bottom: 0.3rem;
      font-weight: 600;
      color: #333333;
      em {
        font-size: 0.8rem;
        font-style: inherit;
      }
      span {
        color: red;
        font-style: italic;
        font-size: 0.8rem;
        font-weight: 600;
      }
    }
    .time {
      font-size: 0.7rem;
      color: #777777;
      span {
        margin-left: 0.5rem;
      }
    }
    .info {
      padding-top: 0.8rem;
      font-size: 0.9rem;
      color: #777777;
    }
    .ewm {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      font-size: 0.8rem;
      color: #777777;
      img {
        width: 5rem;
        height: 5rem;
      }
    }
    &.more {
      padding: 0.5rem 0;
      color: rgb(45, 140, 240);
      font-size: 0.8rem;
      text-align: center;
      cursor: pointer;
    }
  }
}
@media screen and (min-width: 576px) {
  .content {
    padding: 5rem 10rem 2rem;
    .Breadcrumb {
      width: 90%;
      margin: auto;
      .breadcrumb {
        background: transparent;
        padding: 0.75rem 0;
        font-size: 0.9rem;
        a {
          color: #2b85e4;
        }
      }
    }
    .item {
      background: white;
      border-radius: 6px;
      padding: 2rem;
      width: 90%;
      margin: 0 auto 0.8rem;
      position: relative;
      &:first-child {
        .info {
          width: 70%;
        }
      }
      .title {
        font-size: 1.1rem;
        padding-bottom: 0.3rem;
        color: #333333;
        span {
          color: red;
          font-style: italic;
          font-size: 0.9rem;
          font-weight: 600;
        }
      }
      .time {
        font-size: 0.8rem;
        color: #777777;
        span {
          margin-left: 0.5rem;
        }
      }
      .info {
        padding-top: 0.8rem;
        font-size: 0.9rem;
        color: #777777;
      }
      .ewm {
        position: absolute;
        right: 3rem;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        font-size: 0.8rem;
        color: #777777;
        img {
          width: 5rem;
          height: 5rem;
          margin-bottom: 0.2rem;
        }
      }
      &.more {
        padding: 0.5rem 0;
        color: rgb(45, 140, 240);
        font-size: 0.8rem;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>